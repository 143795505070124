






























  import Vue from 'vue';
  import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

  @Component({
    name: 'ProductHelp',
    components: {},
    mixins: [],
  })
  export default class ProductHelp extends Vue {

    @Prop({default: require('@/assets/images/help.png')}) public banner !: string

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
